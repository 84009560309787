import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  ButtonProps,
  Checkbox,
  Form,
  Input,
  Modal,
  Upload,
} from 'antd';
import { InternalFieldProps } from 'rc-field-form/lib/Field';

import { FILE_UPLOAD_API } from '../../api';
import { useDeleteFileMutation } from '../../api/common';
import {
  useCreateProjectMutation,
  useGetProjectsQuery,
  usePatchProjectMutation,
} from '../../api/projects';
import { TCreateProjectForm } from '../../api/projects/types';
import { useAppNotificationApi } from '../../context/providers/app-notifications-provider';
import { TFileUploadResponse } from '../../types/api';
import { TBaseModalProps } from '../../types/ui';
import { isServerError } from '../../utils/server-utils';

import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';

type TProps = TBaseModalProps & {
  existProjectId?: string;
};

type TCreateForm = Omit<TCreateProjectForm, 'feedURL'> &
  (
    | {
        loadWithUrl: true;
        feedURL: string;
      }
    | {
        loadWithUrl: false;
        configFile: [UploadFile<TFileUploadResponse>];
      }
  );

const normFile: InternalFieldProps<TCreateForm>['getValueFromEvent'] = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const CreateProjectModal: FC<TProps> = memo(
  // eslint-disable-next-line react/prop-types
  ({ open, setOpen, existProjectId }) => {
    const [form] = Form.useForm<TCreateForm>();
    const { messageApi } = useAppNotificationApi();
    const [createProject, createApi] = useCreateProjectMutation();
    const [patchProject, patchApi] = usePatchProjectMutation();
    const { data } = useGetProjectsQuery(undefined, {
      skip: !open,
    });

    const selectedProject = useMemo(
      () =>
        existProjectId
          ? data?.projects.find((el) => el.id === existProjectId)
          : undefined,
      [data, existProjectId]
    );

    const [deleteFile] = useDeleteFileMutation();

    const [fileList, setFileList] =
      useState<UploadFile<TFileUploadResponse>[]>();

    const handleCancel = () => {
      setOpen(false);
    };

    useEffect(() => {
      if (open) {
        if (selectedProject) {
          form.setFieldsValue({
            name: selectedProject.name,
            feedURL: selectedProject.feedURL,
            scheduleEnabled: selectedProject.scheduleEnabled,
            statisticsEnabled: selectedProject.statisticsEnabled,
            loadWithUrl: true, // После создания источник всегда внешний
          });
        } else {
          form.resetFields();
        }
      }
    }, [selectedProject, open]);

    const handleFormFinish = useCallback(
      async (values: TCreateForm) => {
        let feedUrl: string;

        if (values.loadWithUrl) {
          feedUrl = values.feedURL;
        } else if (values?.configFile?.[0]?.response) {
          feedUrl = values.configFile[0].response.location;
        } else {
          void messageApi.error('Файл загружен с ошибкой, повторите попытку');
          return;
        }

        // Обработка на случай если файлы загрузят на наше хранилище, а после этого решат грузить из внешнего источника,
        // в таком случае, файлы нужно будет удалить
        if (values.loadWithUrl && fileList && fileList.length > 0) {
          try {
            const removeFileCallback: Promise<unknown>[] = [];

            fileList.forEach((file) => {
              removeFileCallback.push(
                new Promise((res, rej) => {
                  if (file.response?.id) {
                    deleteFile(file.response.id)
                      .unwrap()
                      .then((r) => res(r))
                      .catch((e) => rej(e));
                  }
                })
              );
            });

            await Promise.all([...removeFileCallback]);
            setFileList([]); // Чистим стейт с файлом
            form.setFieldValue('configFile', undefined); // Чистим форму от файла
          } catch {
            // Если вдруг нужна будет обработка ошибки удаления файла
          }
        }

        try {
          const successMessage = existProjectId
            ? 'Проект успешно изменен'
            : 'Проект успешно создан';
          if (existProjectId) {
            await patchProject({
              data: {
                name: values.name,
                feedURL: feedUrl,
                scheduleEnabled: values.scheduleEnabled,
                statisticsEnabled: values.statisticsEnabled,
              },
              id: existProjectId,
            }).unwrap();
          } else {
            await createProject({
              name: values.name,
              feedURL: feedUrl,
              scheduleEnabled: values.scheduleEnabled,
              statisticsEnabled: values.statisticsEnabled,
            }).unwrap();
          }

          void messageApi.success(successMessage);
          handleCancel();
        } catch (e) {
          let errorMessage: string;
          if (isServerError(e) && e.data.message) {
            errorMessage = e.data.message;
          } else if (existProjectId) {
            errorMessage = 'Произошла ошибка при редактировании проекта';
          } else {
            errorMessage = 'Произошла ошибка при создании проекта';
          }

          void messageApi.error(errorMessage);
        }
      },
      [fileList, existProjectId]
    );

    const okButtonProps: ButtonProps = useMemo(
      () => ({
        loading: existProjectId ? patchApi.isLoading : createApi.isLoading,
      }),
      [createApi, patchApi, existProjectId]
    );

    const handleFileChange = useCallback(
      async (e: UploadChangeParam<UploadFile<TFileUploadResponse>>) => {
        setFileList(e.fileList);
        if (e?.file?.status === 'removed' && e.file?.response?.id) {
          await deleteFile(e.file.response.id);
        }
      },
      []
    );

    return (
      <Modal
        title={existProjectId ? 'Редактировать проект' : 'Создать проект'}
        open={open}
        onCancel={handleCancel}
        onOk={form.submit}
        okButtonProps={okButtonProps}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormFinish}
          requiredMark={false}
          initialValues={{
            scheduleEnabled: false,
            loadWithUrl: false,
          }}
        >
          <Form.Item
            label="Название"
            name="name"
            rules={[
              {
                required: true,
                message: 'Адрес обязателен для заполнения',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item valuePropName="checked" name="loadWithUrl">
            <Checkbox>Файл конфигурации из внешнего источника</Checkbox>
          </Form.Item>
          <Form.Item noStyle dependencies={['loadWithUrl']}>
            {({ getFieldValue }) => {
              const isLoadWithUrl: boolean = getFieldValue('loadWithUrl');

              if (isLoadWithUrl) {
                return (
                  <Form.Item
                    label="Адрес файла конфигурации"
                    name="feedURL"
                    rules={[
                      {
                        required: true,
                        message: 'Адрес обязателен для заполнения',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                );
              }

              return (
                <Form.Item
                  label="Файл конфигурации"
                  name="configFile"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                  rules={[
                    {
                      required: true,
                      message: 'Файл обязателен',
                    },
                  ]}
                >
                  <Upload
                    name="file"
                    action={FILE_UPLOAD_API}
                    onChange={handleFileChange}
                    fileList={fileList}
                  >
                    <Button icon={<UploadOutlined />}>
                      Нажмите чтобы загрузить
                    </Button>
                  </Upload>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item valuePropName="checked" name="scheduleEnabled">
            <Checkbox>Включить расписание</Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name="statisticsEnabled">
            <Checkbox>Собирать статистику</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);
