import { TGenerationTask } from '../../types/task';

export enum EFeedDescriptionType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
}

type TFeedUrlDescription = {
  description: string;
  name: string;
  type: EFeedDescriptionType;
};

export type TProject = {
  createAt: string;
  feedURL: string;
  id: string;
  name: string;
  scheduleEnabled: boolean;
  statisticsEnabled?: boolean;
  updateAt: string;
  scheduledFeedURL: string | null;
  feedOfferDescriptions: TFeedUrlDescription[];
};

export type TFullProject = TProject & {
  feedOfferDescriptions: TFeedUrlDescription[];
};

export type TProjectsResponse = {
  count: number;
  projects: TProject[];
};

export type TCreateProjectForm = {
  name: string;
  feedURL: string;
  scheduleEnabled: boolean;
  statisticsEnabled?: boolean;
};

export type TPatchProjectPayload = {
  data: TCreateProjectForm;
  id: string;
};

type TBanner = {
  id: string;
  imageURL: string;
  createAt: string;
  updateAt: string;
  projectId: string;
  taskId: null;
};

export type TProjectBannersResponse = {
  banners: TBanner[];
} & TFullProject;

export type TProjectTasksResponse = {
  generationTasks?: TGenerationTask[];
} & TFullProject;

export type TBannerTemplate = {
  id: string;
  updateAt: string;
  createAt: string;
  height: number;
  width: number;
  frame: {
    asisFileId: string;
    asisFileLocation: string;
    baseFileId: string;
    baseFileLocation: string;
    id: string;
    name: string;
    type: string;
  };
};

export type TProjectTemplateResponse = {
  feedURL: string;
  id: string;
  name: string;
  offersCount: number;
  ownerId: string;
  scheduleEnabled: boolean;
  scheduledFeedURL: null | string;
  updatedAt: string;
  createdAt: string;
  feedOfferDescriptions: TFeedUrlDescription[];
  bannerTemplates: TBannerTemplate[];
};
