import { FC, memo } from 'react';

import { Checkbox, Col, ColProps, Row, Statistic } from 'antd';
import dayjs from 'dayjs';

import { TFullProject } from '../../../api/projects/types';

type TProps = {
  data?: TFullProject;
  loading: boolean;
};

const colProps: Partial<ColProps> = {
  xs: {
    span: 12,
  },
  sm: {
    span: 12,
  },
  md: {
    span: 8,
  },
  lg: {
    span: 6,
  },
  xl: {
    span: 4,
  },
  xxl: {
    span: 3,
  },
};

const ProjectStatistic: FC<TProps> = memo(({ data, loading }) => (
  <Row gutter={16} wrap>
    <Col {...colProps}>
      <Statistic
        title="Дата создания"
        value={dayjs(data?.createAt).format('DD.MM.YYYY')}
        loading={loading}
      />
    </Col>
    <Col {...colProps}>
      <Statistic
        title="Дата обновления"
        value={dayjs(data?.updateAt).format('DD.MM.YYYY')}
        loading={loading}
      />
    </Col>
    <Col {...colProps}>
      <Statistic
        title="Сбор статистики"
        prefix={<Checkbox checked={!!data?.statisticsEnabled} />}
        loading={loading}
        value={data?.statisticsEnabled ? 'Да' : 'Нет'}
      />
    </Col>
    <Col {...colProps}>
      <Statistic
        title="Расписание"
        prefix={<Checkbox checked={!!data?.scheduleEnabled} />}
        loading={loading}
        value={data?.scheduleEnabled ? 'Да' : 'Нет'}
      />
    </Col>
  </Row>
));

export default ProjectStatistic;
